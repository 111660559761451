import React, { ReactNode } from 'react';
import ListItemText, { ListItemTextProps } from '@mui/material/ListItemText';
import { SxProps } from 'src/theme/types';

type Props = ListItemTextProps & {
  label?: string;
  value?: ReactNode;
  zebra?: boolean;
};

const getStyle = (style?: SxProps, zebra?: boolean): SxProps => ({
  display: 'flex',
  flexDirection: 'row-reverse',
  justifyContent: 'space-between',
  ...(zebra && {
    '&:nth-of-type(odd)': {
      backgroundColor: 'secondary.light',
      py: 0.6,
      my: -0.3,
    },
  }),
  ...style,
});

function FieldsSetRow({ label, value, zebra, sx, ...rest }: Props) {
  return (
    <ListItemText
      sx={getStyle(sx, zebra)}
      primary={value}
      secondary={label}
      secondaryTypographyProps={{ marginRight: 1 }}
      primaryTypographyProps={{
        typography: 'caption',
        textAlign: 'right',
      }}
      {...rest}
    />
  );
}

export default FieldsSetRow;
