import { forwardRef, ForwardedRef } from 'react';
import { ChannelMessageSummary } from '@aws-sdk/client-chime-sdk-messaging';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import { formatDateTimeDynamically } from 'src/utils';
import { alpha } from '@mui/material/styles';
import { Theme } from '@mui/material/styles/createTheme';
import Typography from '@mui/material/Typography';
import ChatMessagesListItemAvatar from './ChatMessagesListItemAvatar';

type Props = {
  message: ChannelMessageSummary;
  isMyMessage?: boolean;
};

const myMessageStyle = ({ palette }: Theme) => ({
  alignSelf: 'flex-end',
  width: '90%',
  mr: 2,
  borderRadius: 2,
  borderBottomRightRadius: 0,
  bgcolor: alpha(palette.primary.main, 0.08),
});

const style = {
  width: '85%',
};

function ChatMessagesListItem(
  { message, isMyMessage }: Props,
  ref: ForwardedRef<HTMLLIElement>,
) {
  const { Content, CreatedTimestamp, Sender } = message;

  return (
    <ListItem ref={ref} sx={isMyMessage ? myMessageStyle : style}>
      {!isMyMessage && (
        <ListItemIcon>
          <ChatMessagesListItemAvatar userArn={Sender?.Arn} />
        </ListItemIcon>
      )}
      <ListItemText
        primaryTypographyProps={{ mb: 0.5 }}
        primary={
          <>
            <Typography variant="subtitle1" display="inline" marginRight={1}>
              {Sender?.Name}
            </Typography>
            {formatDateTimeDynamically(CreatedTimestamp)}
          </>
        }
        secondary={Content}
      />
    </ListItem>
  );
}

export default forwardRef(ChatMessagesListItem);
