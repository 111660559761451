import { Ref } from 'react';
import { ChannelMessageSummary } from '@aws-sdk/client-chime-sdk-messaging';
import Stack, { StackProps } from '@mui/material/Stack';
import ListSkeleton from 'src/components/ListSkeleton';
import NoContent from 'src/components/NoContent';
import ChatMessagesListItem from './ChatMessagesListItem';
import ChatMessagesListSkeletonRow from './ChatMessagesListSkeletonRow';

type Props = {
  isLoading?: boolean;
  messages?: ChannelMessageSummary[];
  userArn?: string;
  lastElementRef?: Ref<HTMLLIElement>;
};

const style: StackProps = {
  direction: 'column-reverse',
  overflow: 'auto',
  flex: 1,
  gap: 1.5,
};

function ChatMessagesList({
  isLoading,
  messages,
  userArn,
  lastElementRef,
}: Props) {
  return (
    <Stack {...style}>
      {(isLoading || !messages?.length) && (
        <ListSkeleton
          loading={isLoading}
          rowComponent={ChatMessagesListSkeletonRow}
          justifyContent="space-between"
          rows={10}
          flex={1}
        >
          <NoContent
            title="You don`t have any messages"
            description="Send yours to start conversation"
          />
        </ListSkeleton>
      )}
      {messages?.map((message, index) => {
        const isLastElement = index === messages.length - 1;
        return (
          <ChatMessagesListItem
            ref={isLastElement ? lastElementRef : undefined}
            key={message.MessageId}
            message={message}
            isMyMessage={message.Sender?.Arn === userArn}
          />
        );
      })}
    </Stack>
  );
}

export default ChatMessagesList;
