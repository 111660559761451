import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { loadDataFromStorage } from 'src/services';
import { useAppSelector } from 'src/store/hooks';
import { setUsersColors } from './actions';
import { CHAT_STORE_KEY } from './constants';
import { ChatUsersState } from './types';
import { getUpdatedUsersColors } from './utils';

const initialState: ChatUsersState = loadDataFromStorage(CHAT_STORE_KEY) || {};

const reducers = {
  setUsersColors: (
    state: ChatUsersState,
    { payload }: PayloadAction<string[]>,
  ) => getUpdatedUsersColors(state, payload),
};

export const chatSlice = createSlice({
  name: CHAT_STORE_KEY,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setUsersColors, reducers.setUsersColors);
  },
});

export const useChatUsersState = () => useAppSelector(({ chat }) => chat);
