import Stack from '@mui/material/Stack';
import {
  getChannelDataByArn,
  useGetChatChannelsQuery,
} from 'src/entities/chat';
import { useSingleQueryStateValue } from 'src/hooks';
import ChatMessages from './ChatMessages';
import ChatChannels from './ChatChannels';

const channelQueryParamKey = 'channelArn';

function Chat() {
  const [selectedArn, setSelectedArn] =
    useSingleQueryStateValue<string>(channelQueryParamKey);
  const { data: selectedChannel } = useGetChatChannelsQuery(undefined, {
    selectFromResult: ({ data }) => getChannelDataByArn(data, selectedArn),
  });

  return (
    <Stack height="calc(100vh - 10rem)" direction="row">
      {selectedChannel && <ChatMessages channel={selectedChannel} />}
      <ChatChannels selected={selectedChannel} onSelect={setSelectedArn} />
    </Stack>
  );
}

export default Chat;
