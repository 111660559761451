import React, { MouseEvent, ForwardedRef, forwardRef, useContext } from 'react';
import Link from '@mui/material/Link';
import IconButton from '@mui/material/IconButton';
import SvgIcon from '@mui/material/SvgIcon';
import {
  TreeItemContent,
  TreeItemContentProps,
} from '@mui/x-tree-view/TreeItem';
import DownloadIcon from 'src/assets/icons/download-icon.svg?react';
import DeleteForeverIcon from 'src/assets/icons/delete-forever.svg?react';
import MoreHorizIcon from 'src/assets/icons/more-horiz.svg?react';
import { DataGroup } from 'src/entities/dicts';
import { DataRoomItem, DataRoomItemType } from 'src/entities/dataRoom';
import { API_VERSION } from 'src/services';
import { SxProps } from 'src/theme/types';
import Stack from '@mui/material/Stack';
import { FilesDataRoomContext } from './FilesDataRoomContext';

const linkStyle: SxProps = {
  display: 'inline-block',
  width: '100%',
  pl: 0.8,
};

function FilesDataRoomItem(
  { label, ...props }: TreeItemContentProps & DataRoomItem,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const { classes, link, itemType, itemId } = props;
  const {
    showDownloadFilesModal,
    showDeleteFileModal,
    group,
    handleMenuClick,
    canUpdate,
  } = useContext(FilesDataRoomContext);

  const handleDownload = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    showDownloadFilesModal(itemId);
  };

  const handleDeleteFile = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    showDeleteFileModal(itemId, label);
  };

  if (itemType === DataRoomItemType.folder) {
    return (
      <TreeItemContent
        ref={ref}
        label={
          <>
            {label}
            {group === DataGroup.loanApps && (
              <IconButton
                onClick={(event: MouseEvent<HTMLButtonElement>) => {
                  handleMenuClick(event, { label, ...props } as DataRoomItem);
                }}
              >
                <SvgIcon component={MoreHorizIcon} />
              </IconButton>
            )}
            {group !== DataGroup.loanApps && (
              <IconButton onClick={handleDownload}>
                <SvgIcon component={DownloadIcon} />
              </IconButton>
            )}
          </>
        }
        {...props}
      />
    );
  }
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        padding: '0.5rem 1rem',
        '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' },
      }}
    >
      <Link
        target="_blank"
        classes={classes}
        href={`${import.meta.env.REACT_APP_API_PATH}${API_VERSION}${link}`}
        sx={linkStyle}
      >
        {label}
      </Link>
      {canUpdate && (
        <IconButton onClick={handleDeleteFile} size="small" sx={{ padding: 0 }}>
          <SvgIcon component={DeleteForeverIcon} />
        </IconButton>
      )}
    </Stack>
  );
}

export default forwardRef(FilesDataRoomItem);
