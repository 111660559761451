import React from 'react';
import { StackProps } from '@mui/material/Stack';
import ListSkeleton from '../ListSkeleton';

type Props = {
  loading?: boolean;
  rows?: number;
} & StackProps;

function SideModalSkeleton({ rows = 12, ...rest }: Props) {
  return <ListSkeleton rows={rows} gap={1.2} mt={4} {...rest} />;
}

export default SideModalSkeleton;
