import { useMemo } from 'react';
import { ChatChannel, getChannelDisplayName } from 'src/entities/chat';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

type Props = {
  channel?: ChatChannel;
};

function ChaMessagesHeader({ channel }: Props) {
  const { description, helperText } = useMemo(
    () => getChannelDisplayName(channel),
    [channel],
  );

  return (
    <>
      <Box>
        <Typography variant="h7" mr={1}>
          {description}
        </Typography>
        {helperText && (
          <Typography variant="h8" color="text.secondary">
            ({helperText})
          </Typography>
        )}
      </Box>
      <Divider />
    </>
  );
}

export default ChaMessagesHeader;
