import React from 'react';
import { Navigate } from 'react-router-dom';
import FileIcon from 'src/assets/icons/file-icon.svg?react';
import FileSolidIcon from 'src/assets/icons/file-solid-icon.svg?react';
import FlameIcon from 'src/assets/icons/flame-icon.svg?react';
import FlameSolidIcon from 'src/assets/icons/flame-solid-icon.svg?react';
import HomeIcon from 'src/assets/icons/home-icon.svg?react';
import HomeSolidIcon from 'src/assets/icons/home-solid-icon.svg?react';
import MessageIcon from 'src/assets/icons/message-icon.svg?react';
import MessageSolidIcon from 'src/assets/icons/message-solid-icon.svg?react';
import PowerIcon from 'src/assets/icons/power-icon.svg?react';
import PowerSolidIcon from 'src/assets/icons/power-solid-icon.svg?react';
import SettingsIcon from 'src/assets/icons/settings-icon.svg?react';
import SettingsSolidIcon from 'src/assets/icons/settings-solid-icon.svg?react';
import UserIcon from 'src/assets/icons/user-icon.svg?react';
import { UserRole } from '../entities/dicts';
import {
  ConfirmAccount,
  ForgotPassword,
  Login,
  Register,
  SetNewPassword,
} from '../pages/Auth';
import Dashboard from '../pages/Dashboard';
import FundingOpps from '../pages/FundingOpps';
import LoanApps from '../pages/LoanApps';
import LoanApp, { LoanAppTitle } from '../pages/LoanApp';
import CreditorApp, { CreditorAppTitle } from '../pages/CreditorApp';
import Chat from '../pages/Chat';
import NotFound from '../pages/NotFound';
import Settings from '../pages/Settings';
import * as ROUTES from './constants';
import { RouteAccessStatus, RouteProps } from './types';

export const routes: RouteProps[] = [
  {
    path: ROUTES.ROOT_ALL,
    element: <Navigate to={ROUTES.DASHBOARD} />,
  },
  {
    path: ROUTES.LOGIN,
    element: <Login />,
    allowedForStatus: RouteAccessStatus.Unauthorized,
    meta: {
      title: 'Sign in',
    },
  },
  {
    path: ROUTES.FORGOT_PASSWORD,
    element: <ForgotPassword />,
    allowedForStatus: RouteAccessStatus.Unauthorized,
    meta: {
      title: 'Forgot password?',
    },
  },
  {
    path: ROUTES.SET_NEW_PASSWORD,
    element: <SetNewPassword />,
    allowedForStatus: RouteAccessStatus.Unauthorized,
    requireState: true,
    meta: {
      title: 'Set new password',
    },
  },
  {
    path: ROUTES.REGISTER,
    element: <Register />,
    allowedForStatus: RouteAccessStatus.Unauthorized,
    meta: {
      title: 'Sign up',
    },
  },
  {
    path: ROUTES.CONFIRM_ACCOUNT,
    element: <ConfirmAccount />,
    allowedForStatus: RouteAccessStatus.Unauthorized,
    requireState: true,
    meta: {
      title: 'Confirm your account',
    },
  },
  {
    path: ROUTES.DASHBOARD,
    element: <Dashboard />,
    meta: {
      title: 'Dashboard',
      icon: HomeIcon,
      activeIcon: HomeSolidIcon,
      navLink: true,
    },
  },
  {
    path: ROUTES.FUNDING_OPPS,
    element: <FundingOpps />,
    allowedForRoles: [UserRole.CreditOfficer],
    meta: {
      title: 'Funding Opportunities',
      icon: PowerIcon,
      activeIcon: PowerSolidIcon,
      navLink: true,
    },
  },
  {
    path: ROUTES.LOAN_APPS,
    element: <LoanApps />,
    meta: {
      title: 'Loan Applications',
      icon: FileIcon,
      activeIcon: FileSolidIcon,
      navLink: true,
    },
  },
  {
    path: ROUTES.LOAN_APPLICATION,
    element: <LoanApp />,
    allowedForStatus: RouteAccessStatus.All,
    meta: {
      title: <LoanAppTitle />,
    },
  },
  {
    path: ROUTES.CREDITOR_APPLICATION,
    element: <CreditorApp />,
    meta: {
      title: <CreditorAppTitle />,
    },
  },
  {
    path: ROUTES.PRODUCTS,
    element: <NotFound />,
    meta: {
      title: 'Available Products',
      icon: FlameIcon,
      activeIcon: FlameSolidIcon,
    },
  },
  {
    path: ROUTES.CHAT,
    element: <Chat />,
    meta: {
      title: 'Live Chat',
      icon: MessageIcon,
      activeIcon: MessageSolidIcon,
      navLink: true,
    },
  },
  {
    path: ROUTES.ACCOUNT,
    element: <NotFound />,
    meta: {
      title: 'Account',
      icon: UserIcon,
    },
  },
  {
    path: ROUTES.SETTINGS,
    element: <Settings />,
    meta: {
      title: 'Settings',
      icon: SettingsIcon,
      activeIcon: SettingsSolidIcon,
      navLink: true,
    },
  },
];

export default routes;
