import React, { ComponentType, ReactNode } from 'react';
import Stack, { StackProps } from '@mui/material/Stack';
import { SxProps } from 'src/theme/types';
import { isEmpty, toKebabCase } from 'src/utils';
import {
  FieldDataItem,
  FieldSetItemComponentProps,
  FieldSetValue,
  FieldsSetData,
} from './types';
import FieldsSetSkeleton from './FieldsSetSkeleton';
import FieldsSetRow from './FieldsSetRow';

type Props<Data extends FieldsSetData> = StackProps & {
  data?: Data;
  fields: FieldDataItem[];
  defaultValue?: string;
  defaultComponent?: ComponentType<FieldSetItemComponentProps>;
  defaultFormatter?: (value: FieldSetValue) => ReactNode;
  loading?: boolean;
  skipEmpty?: boolean;
  zebra?: boolean;
  rowStyle?: SxProps;
};

function FieldsSet<Data extends FieldsSetData = FieldsSetData>({
  data,
  fields,
  loading,
  skipEmpty,
  zebra,
  rowStyle,
  defaultComponent,
  defaultFormatter = (value) => value,
  defaultValue = '–',
  ...rest
}: Props<Data>) {
  if (loading) {
    return (
      <FieldsSetSkeleton
        data-testid="fields-set-skeleton"
        rows={fields.length}
      />
    );
  }
  return (
    <Stack gap={0.3} {...rest}>
      {data &&
        fields.map(
          ({
            id,
            label,
            formatter = defaultFormatter,
            Component = defaultComponent,
          }) => {
            const value = data[id];
            return (
              (!skipEmpty || value) && (
                <FieldsSetRow
                  data-testid={`fields-set-row-${toKebabCase(id)}`}
                  key={id}
                  zebra={zebra}
                  label={label}
                  value={
                    Component ? (
                      <Component
                        defaultValue={defaultValue}
                        value={value}
                        formatter={formatter}
                      />
                    ) : (
                      (!isEmpty(value) && formatter(value)) || defaultValue
                    )
                  }
                />
              )
            );
          },
        )}
    </Stack>
  );
}

export default FieldsSet;
