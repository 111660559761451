import React from 'react';
import { useNavigate } from 'react-router-dom';
import { LOAN_APPLICATION as ROUTE_LOAN_APPLICATION } from 'src/router';
import NoContent from 'src/components/NoContent';
import { DataListSkeleton, DataListWrapper } from 'src/components/DataList';
import { PagingParams, usePagingParams } from 'src/components/Paging';
import { LoanAppStatus, useGetLoanAppsQuery } from 'src/entities/loanApps';
import LoanAppsListItem from './LoanAppsListItem';

type Props = PagingParams & {
  status?: LoanAppStatus[];
};

function LoanAppsList({ pageSize: initialPageSize, status }: Props) {
  const navigate = useNavigate();
  const pagingParams = usePagingParams(initialPageSize);
  const { page, pageSize } = pagingParams;
  const { data, isLoading } = useGetLoanAppsQuery({ page, pageSize, status });
  const { data: items, total } = data || {};
  const noData = !isLoading && !items?.length;

  if (isLoading || noData) {
    return (
      <DataListSkeleton loading={isLoading}>
        <NoContent
          title="You don’t have loan applications yet"
          description="Click on “Get loan” to submit a new loan application"
          actionText="Get loan"
          onClick={() => navigate(ROUTE_LOAN_APPLICATION)}
        />
      </DataListSkeleton>
    );
  }
  return (
    <DataListWrapper pagingProps={{ total, ...pagingParams }}>
      {items?.map((item) => <LoanAppsListItem key={item.id} {...item} />)}
    </DataListWrapper>
  );
}

export default LoanAppsList;
