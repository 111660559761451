import { PagingParams } from 'src/components/Paging';
import { PartyType } from '../customer';
import { Currency } from '../currency';
import { BorrowerType, CollateralType, LoanType, Sector } from '../dicts';

export enum LoanAppStatus {
  Draft = 'DRAFT',
  Processing = 'PROCESSING',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  SourcingOfCapital = 'SOURCING_OF_CAPITAL',
  Funded = 'FUNDED',
  Inactive = 'INACTIVE',
}

export enum LoanAppFormField {
  Step = 'step',
  PartyType = 'partyType',
  LoanAmount = 'loanAmount',
  LoanCurrency = 'loanCurrency',
  LoanType = 'loanType',
  LoanTerm = 'loanTerm',
  CollateralTypes = 'collateralTypes',
  FullCompanyName = 'fullCompanyName',
  Description = 'description',
  RegistrationNumber = 'registrationNumber',
  Website = 'website',
  AdditionalInfo = 'additionalInfo',
  AddressLine1 = 'addressLine1',
  AddressLine2 = 'addressLine2',
  City = 'city',
  StateProvince = 'stateProvince',
  Country = 'country',
  PostalCode = 'postalCode',
  AddressIsSame = 'addressIsSame',
  PrimaryAddressLine1 = 'primaryAddressLine1',
  PrimaryAddressLine2 = 'primaryAddressLine2',
  PrimaryCity = 'primaryCity',
  PrimaryCountry = 'primaryCountry',
  PrimaryPostalCode = 'primaryPostalCode',
  PrimaryStateProvince = 'primaryStateProvince',
  BirthDate = 'birthDate',
  Residency = 'residency',
  Citizenship = 'citizenship',
  WealthSource = 'wealthSource',
  ContactPersons = 'contactPersons',
  Name = 'name',
  Surname = 'surname',
  PersonRelType = 'personRelType',
  PhoneNumber = 'phoneNumber',
  Email = 'email',
  Docs = 'docs',
  IdScan = 'idScan',
  UtilityScan = 'utilityScan',
  WealthSourceScan = 'wealthSourceScan',
  RecentAnnualFinStatements = 'recentAnnualFinancialStatements',
  RecentQuarterFinStatements = 'recentQuarterlyFinancialStatements',
}

export enum LoanAppItemField {
  LoanAmount = 'loanAmount',
  LoanCurrency = 'loanCurrency',
  loanTypeCode = 'loanTypeCode',
  LoanTerm = 'loanTerm',
  RegionCode = 'regionCode',
  SectorCode = 'sectorCode',
  AdditionalInfo = 'additionalInfo',
}

export type LoanAppFormLoanParams = {
  loanAmount?: number;
  loanCurrency?: Currency;
  loanType?: CollateralType;
  loanTerm?: number;
  additionalInfo?: string;
};

export type LoanAppFormBusinessInfo = {
  fullCompanyName?: string;
  registrationNumber?: string;
  description?: string;
  website?: string;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  stateProvince?: string;
  country?: string;
  postalCode?: string;
  addressIsSame?: boolean;
  primaryAddressLine1?: string;
  primaryAddressLine2?: string;
  primaryCity?: string;
  primaryCountry?: string;
  primaryPostalCode?: string;
  primaryStateProvince?: string;
};

export type LoanAppFormContactPerson = {
  name?: string;
  surname?: string;
  personRelType?: string;
  phoneNumber?: string;
  email?: string;
};
export type LoanAppFormContactPersons = {
  contactPersons?: LoanAppFormContactPerson[];
};

export type LoanAppFormSupportingDocs = {
  docs?: string[];
};

export type LoanAppFormParams = LoanAppFormLoanParams &
  LoanAppFormBusinessInfo &
  LoanAppFormContactPersons &
  LoanAppFormSupportingDocs;

export type LoanAppState = {
  lastModifiedDate?: string;
  partyType: PartyType;
  step?: number;
} & Partial<LoanAppFormParams>;

export type LoanAppPayload = Partial<LoanAppState>;

export type LoanAppItem = {
  additionalInfo: string;
  borrowerTypeCode: BorrowerType;
  createdBy: string;
  createdDate: string;
  currency: Currency;
  id: number;
  isBorrowerConfirmationRequired: boolean;
  initiatorLogin: string;
  loanAmount: string;
  loanTerm: number;
  loanTypeCode: LoanType;
  modifiedBy: string;
  modifiedDate: string;
  partyId: number;
  partyType: PartyType;
  rawdata: LoanAppPayload;
  regionCode: string;
  sectorCode: Sector;
  statusCode: LoanAppStatus;
  targetedClosure: string;
};

export type LoanAppsListParams = PagingParams & {
  status?: LoanAppStatus[];
};

export type LoanAppCreditor = {
  creditorFullName: string;
  creditorId: number;
  id: number;
  isConfirmedByBorrower: boolean;
  canBeConfirmed: boolean;
  chimeChannelArn?: string;
};

export type LoanAppConfirmCreditorsParams = {
  id: number;
  creditorIds: string[];
};
