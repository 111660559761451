import Avatar from '@mui/material/Avatar';
import { ChatUsersState, useChatUsersState } from 'src/entities/chat';

type Props = {
  userArn?: string;
};

function ChatMessagesListItemAvatar({ userArn }: Props) {
  const chatState = useChatUsersState();
  const color = chatState[userArn as keyof ChatUsersState];

  return (
    <Avatar
      color="primary"
      sx={{
        borderRadius: '50%',
        color: 'text.primary',
        bgcolor: color,
      }}
    />
  );
}

export default ChatMessagesListItemAvatar;
