import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import List from '@mui/material/List';
import Stack, { StackProps } from '@mui/material/Stack';
import { ROOT as ROUTE_ROOT } from 'src/router/constants';
import {
  useGetChatChannelsQuery,
  ChatChannel,
  useChatChannelsState,
  getCurrentChannels,
} from 'src/entities/chat';
import ListSkeleton from 'src/components/ListSkeleton';
import NoContent from 'src/components/NoContent';
import ChatChannelsListItem from './ChatChannelsListItem';
import ChatChannelsSkeletonRow from './ChatChannelsSkeletonRow';

type Props = {
  selected?: ChatChannel;
  onSelect: (value: ChatChannel) => void;
};

const style: StackProps = {
  direction: 'column',
  gap: 2,
  maxHeight: '100%',
  overflow: 'auto',
};

function ChatChannels({ onSelect, selected }: Props) {
  const navigate = useNavigate();
  const { data: rawData, isLoading } = useGetChatChannelsQuery();
  const state = useChatChannelsState();
  const data = useMemo(
    () => getCurrentChannels(state, rawData),
    [rawData, state],
  );

  useEffect(() => {
    if (data && !selected) {
      const [item] = data;
      onSelect(item);
    }
  }, [data, selected, onSelect]);

  if (isLoading || !data?.length) {
    return (
      <ListSkeleton
        flex={1}
        rows={8}
        loading={isLoading}
        rowComponent={ChatChannelsSkeletonRow}
      >
        <NoContent
          title="No chat channels"
          description="Please try to check it later"
          actionText="Go home"
          onClick={() => navigate(ROUTE_ROOT)}
        />
      </ListSkeleton>
    );
  }
  return (
    <Stack {...style}>
      <List>
        {data?.map((item) => (
          <ChatChannelsListItem
            key={item.channelSummary.channelArn}
            onClick={() => onSelect(item)}
            selected={
              selected?.channelSummary.channelArn ===
              item.channelSummary.channelArn
            }
            item={item}
          />
        ))}
      </List>
    </Stack>
  );
}

export default ChatChannels;
