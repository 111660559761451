import {
  differenceInDays,
  differenceInSeconds,
  differenceInCalendarYears,
  isValid,
  isToday,
  isYesterday,
  format,
  formatISO,
  formatDistanceStrict,
  subWeeks,
} from 'date-fns';

export const DATE_FORMAT = 'dd/MM/y';
export const DATE_TIME_FORMAT = 'HH:mm:ss dd/MM/y';
export const DATE_TIME_SHORT_FORMAT = 'MMM dd, HH:mm';
export const DATE_TIME_SHORT_FORMAT_WITH_YEAR = 'MMM dd, yyyy, HH:mm';
export const TIME_SHORT_FORMAT = 'HH:mm';

type DateValue = Date | string | null;

export enum DateUnit {
  Day = 'day',
  Hour = 'hour',
  Second = 'second',
}

export const monthsToYears = (months: number) => Math.round(months / 1.2) / 10;

export const getDate = (date?: DateValue) =>
  date ? new Date(date) : new Date();

export const formatDate = (date?: DateValue, pattern?: string) =>
  format(getDate(date), pattern || DATE_FORMAT);

export const formatDateTime = (date?: DateValue) =>
  formatDate(date, DATE_TIME_FORMAT);

export const formatDateTimeDynamically = (date?: DateValue) => {
  const dateVal = getDate(date);
  if (isToday(dateVal)) {
    return format(dateVal, TIME_SHORT_FORMAT);
  }
  if (isYesterday(dateVal)) {
    return `Yesterday, ${format(dateVal, TIME_SHORT_FORMAT)}`;
  }
  if (differenceInCalendarYears(new Date(), dateVal) === 0) {
    return format(dateVal, DATE_TIME_SHORT_FORMAT);
  }
  return format(dateVal, DATE_TIME_SHORT_FORMAT_WITH_YEAR);
};

export const getTimeZone = () => formatDate(null, 'z');

export const getDatesOffset = (
  firstDate?: DateValue,
  secondDate?: DateValue,
  unit = DateUnit.Day,
) =>
  formatDistanceStrict(getDate(firstDate), getDate(secondDate), {
    unit,
  });

export const getSubWeeksIsoDate = (weeks: number) =>
  formatISO(subWeeks(getDate(), weeks));

export const isDatePast = (date?: DateValue, unit = DateUnit.Second) => {
  const diffFn = unit === DateUnit.Day ? differenceInDays : differenceInSeconds;
  return diffFn(getDate(), getDate(date)) > 0;
};

export const isDateDayPast = (date?: DateValue) =>
  isDatePast(date, DateUnit.Day);

export const isDateValid = (date?: DateValue) => isValid(getDate(date));
