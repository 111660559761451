import ListItemText from '@mui/material/ListItemText';
import ListItemButton, {
  ListItemButtonProps,
} from '@mui/material/ListItemButton';
import Typography from '@mui/material/Typography';
import SvgIcon from '@mui/material/SvgIcon';
import CircleIcon from 'src/assets/icons/circle-icon.svg?react';
import { getChannelDisplayName, ChatChannel } from 'src/entities/chat';

type Props = Pick<ListItemButtonProps, 'selected' | 'onClick'> & {
  item: ChatChannel;
};

function ChatChannelsListItem({ onClick, selected, item }: Props) {
  const { hasNewMessages } = item.userMembershipSummaryDto;
  const { name, description, helperText, updatedAt } =
    getChannelDisplayName(item);

  return (
    <ListItemButton
      sx={{ borderRadius: 0 }}
      selected={selected}
      onClick={onClick}
    >
      <ListItemText
        primaryTypographyProps={{
          variant: 'subtitle1',
          color: 'text.primary',
          mb: 0.3,
        }}
        primary={name}
        secondary={
          <>
            <Typography mb={0.3}>
              <b>{description}</b> ({helperText})
            </Typography>
            <Typography>
              {hasNewMessages && (
                <SvgIcon
                  component={CircleIcon}
                  color="primary"
                  fontSize="xs"
                  sx={{ mr: 0.5 }}
                />
              )}
              {updatedAt}
            </Typography>
          </>
        }
      />
    </ListItemButton>
  );
}

export default ChatChannelsListItem;
