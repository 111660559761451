import {
  ChannelMode,
  ChannelPrivacy,
  ChimeSDKMessagingClientConfig,
} from '@aws-sdk/client-chime-sdk-messaging';
import { Currency } from '../currency';
import { LoanType } from '../dicts';
import { PartyType } from '../customer';

export enum ChatEventType {
  CreateMessage = 'CREATE_CHANNEL_MESSAGE',
}

export enum ChatRequestorRole {
  ContactPerson = 'CONTACT_PERSON',
}

export enum ChatRequestorType {
  Borrower = 'BORROWER',
  CreditOfficer = 'CREDIT_OFFICER',
}

export enum ChatSubjectType {
  Opportunity = 'OPPORTUNITY',
}

export enum ChatUserAvatarColor {
  Dark = 'grey.400',
  Light = 'secondary.light',
  Green = 'success.light',
  Red = 'error.light',
  Ogange = 'warning.light',
  Purple = 'info.light',
}

export type ChatUsersState = Record<string, ChatUserAvatarColor>;

export type ChatSessionData = ChimeSDKMessagingClientConfig & {
  userArn: string;
};

export type ChatChannelLoanApp = {
  currency: Currency;
  id: number;
  loanAmount: number;
  loanTerm: number;
  loanTypeCode: LoanType;
  partyId: number;
  partyType: PartyType;
  regionCode: string;
};

export type ChatChannelSubjectDto = {
  borrowerId: number;
  borrowerName: string;
  channelSubjectType: ChatSubjectType;
  creditorId: number;
  creditorName: string;
  loanApplication: ChatChannelLoanApp;
  loanApplicationId: number;
  loanApplicationName: string;
  opportunityId: number;
  requestorType: ChatRequestorType;
  roleOfRequestor: ChatRequestorRole;
};

export type ChatChannelSummary = {
  channelArn: string;
  channelName: string;
  lastMessageTimestamp: string;
  mode: ChannelMode;
  privacy: ChannelPrivacy;
};

export type ChatChannel = {
  channelSummary: ChatChannelSummary;
  channelSubjectDto: ChatChannelSubjectDto;
};
