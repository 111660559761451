export const cloneObject = <T extends Record<string, unknown>>(obj: T): T =>
  JSON.parse(JSON.stringify(obj));

export const compareObjects = <T extends Record<string, unknown>>(
  obj1: T,
  obj2: T,
  keys?: string[] | null,
) => {
  const compareKeys = keys ?? Object.keys(obj1);
  return compareKeys.every((key) => obj1[key] === obj2[key]);
};

export const filterObject = <T>(
  object: Record<string, T>,
  predicate = (value: T) => !!value,
) =>
  Object.entries(object)
    .filter(([key, value]) => key && predicate(value))
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

export const filterObjectByKeys = <T>(
  object: Record<string, T>,
  keys: string[],
): Record<string, T> =>
  Object.entries(object).reduce(
    (acc, [key, value]) =>
      keys.includes(key) ? { ...acc, [key]: value } : { ...acc },
    {},
  );

export const getObjValOccurrences = <T extends string | number>(
  obj: Record<string, T>,
  searchValues: T[],
) => {
  const initialCounts = searchValues.reduce(
    (acc, value) => ({ ...acc, [value]: 0 }),
    {} as Record<T, number>,
  );
  return Object.values(obj).reduce(
    (counts, value) => ({
      ...counts,
      [value]: searchValues.includes(value) ? counts[value] + 1 : counts[value],
    }),
    initialCounts,
  );
};

export const getObjMinOccurEntry = <T extends string | number>(
  obj: Record<string, T>,
  searchValues: T[],
): [T, number] => {
  const entries = Object.entries(
    getObjValOccurrences(obj, searchValues),
  ) as Array<[T, number]>;
  return entries.reduce(
    (minEntry, currentEntry) =>
      currentEntry[1] < minEntry[1] ? currentEntry : minEntry,
    entries[0] as [T, number],
  );
};

export const mapObject = <T>(
  obj: Record<string, T>,
  fn: (key: string, value: T) => unknown,
) =>
  Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [key, fn(key, value)]),
  );

export const mapObjToFormData = <T>(obj: Record<string, T>) => {
  const data = new FormData();
  Object.entries(obj).forEach(([key, value]) => {
    const isFilelist = value instanceof FileList;
    if (isFilelist) {
      Array.from(value).forEach((file) => data.append(key, file));
    } else {
      data.append(key, value as string);
    }
  });
  return data;
};

export const splitObjectBy = <T>(
  obj: Record<string, T>,
  fn: (key: string, value: T) => unknown,
) =>
  Object.entries(obj).reduce(
    ([pass, fail], [key, value]) =>
      fn(key, value)
        ? [{ ...pass, [key]: value }, fail]
        : [pass, { ...fail, [key]: value }],
    [{}, {}],
  );
