import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  ChannelMessageSummary,
  ChimeSDKMessagingClient,
  ListChannelMessagesCommand,
  SortOrder,
} from '@aws-sdk/client-chime-sdk-messaging';
import {
  DefaultMessagingSession,
  MessagingSessionObserver,
} from 'amazon-chime-sdk-js';
import { setUsersColors } from './actions';
import { chatApi } from './api';
import { CHAT_MESSAGES_PAGE_SIZE, CHAT_SESSION_TAG } from './constants';
import { ChatEventType, ChatSessionData } from './types';
import { useChatUsersState } from './slice';
import {
  getMessagingClient,
  getMessagingSession,
  getUserArnsFromMsgList,
} from './utils';

export const useMessagingSession = (params?: ChatSessionData) => {
  const dispatch = useDispatch();
  const client = useMemo(() => getMessagingClient(params), [params]);
  const session = useMemo(
    () => getMessagingSession(client, params?.userArn),
    [client, params?.userArn],
  );

  useEffect(() => {
    session?.start();
    return session?.stop();
  }, [session]);

  useEffect(() => {
    const messagingObserver: MessagingSessionObserver = {
      messagingSessionDidStop: () =>
        dispatch(chatApi.util.invalidateTags([CHAT_SESSION_TAG])),
    };
    session?.addObserver(messagingObserver);
    return () => session?.removeObserver(messagingObserver);
  }, [dispatch, session]);

  return { client, session };
};

type UseMessagesListParams = {
  client?: ChimeSDKMessagingClient;
  session?: DefaultMessagingSession;
  userArn?: string;
  channelArn?: string;
};

export const useMessagesList = ({
  client,
  session,
  userArn,
  channelArn,
}: UseMessagesListParams) => {
  const [isLoading, setIsLoading] = useState(false);
  const [messages, setMessages] = useState<ChannelMessageSummary[]>([]);
  const [nextToken, setNextToken] = useState<string | undefined>();

  const loadMessages = useCallback(
    (isFirstPage?: boolean) => {
      if (client && (nextToken || isFirstPage)) {
        const command = new ListChannelMessagesCommand({
          ChimeBearer: userArn,
          ChannelArn: channelArn,
          NextToken: !isFirstPage ? nextToken : undefined,
          MaxResults: CHAT_MESSAGES_PAGE_SIZE,
          SortOrder: SortOrder.DESCENDING,
        });
        setIsLoading(true);
        client.send(command).then(({ ChannelMessages = [], NextToken }) => {
          setMessages((prevMessages) => [...prevMessages, ...ChannelMessages]);
          setNextToken(NextToken);
          setIsLoading(false);
        });
      }
    },
    [client, channelArn, userArn, nextToken],
  );

  useEffect(() => {
    setMessages([]);
    loadMessages(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channelArn, userArn]);

  useEffect(() => {
    const messagingObserver: MessagingSessionObserver = {
      messagingSessionDidReceiveMessage: ({ type, payload }) => {
        if (type === ChatEventType.CreateMessage) {
          const newMessage = JSON.parse(payload);
          if (newMessage.ChannelArn === channelArn) {
            setMessages((prevMessages) => [newMessage, ...prevMessages]);
          }
        }
      },
    };

    session?.addObserver(messagingObserver);
    return () => session?.removeObserver(messagingObserver);
  }, [channelArn, session]);

  return { messages, loadMessages, isLoading };
};

export const useSetupUsersColors = (
  messages: ChannelMessageSummary[],
  ownUserArn?: string,
) => {
  const currentUserColors = useChatUsersState();
  const dispatch = useDispatch();

  useEffect(() => {
    if (messages && ownUserArn) {
      const userArnArray = getUserArnsFromMsgList(messages, ownUserArn);
      dispatch(setUsersColors(userArnArray));
    }
  }, [currentUserColors, dispatch, messages, ownUserArn]);
};
