import { EffectCallback, Ref, useEffect, useMemo, useRef } from 'react';
import { Theme } from '@mui/material/styles/createTheme';
import useMediaQuery from '@mui/material/useMediaQuery';
import { splitObjectBy } from 'src/utils';

export const useLayoutBreakpoints = () => {
  const isDesktop = useMediaQuery<Theme>(({ breakpoints }) =>
    breakpoints.up('lg'),
  );
  const isMobile = useMediaQuery<Theme>(({ breakpoints }) =>
    breakpoints.down('sm'),
  );
  const isTablet = !isDesktop && !isMobile;

  return { isDesktop, isMobile, isTablet };
};

export const useLayoutElementProps = <T extends Record<string, unknown>>(
  props: T,
) =>
  useMemo(() => splitObjectBy(props, (key) => key.includes('data-')), [props]);

export const useIntersectionObserver = <T extends HTMLElement>(
  callback: EffectCallback,
  config?: IntersectionObserverInit,
): Ref<T> => {
  const elementRef = useRef(null);

  useEffect(() => {
    const observerCallback: IntersectionObserverCallback = ([entry]) =>
      entry.isIntersecting && callback();
    const observer = new IntersectionObserver(observerCallback, config);
    const { current } = elementRef;

    if (current) {
      observer.observe(current);
    }
    return () => {
      if (current) {
        observer.unobserve(current);
      }
    };
  }, [config, callback]);

  return elementRef;
};
