import Avatar from '@mui/material/Avatar';
import { useChatUserState } from 'src/entities/chat';

type Props = {
  userArn?: string;
};

function ChatMessagesListItemAvatar({ userArn }: Props) {
  const color = useChatUserState(userArn);

  return (
    <Avatar
      color="primary"
      sx={{
        borderRadius: '50%',
        color: 'text.primary',
        bgcolor: color,
      }}
    />
  );
}

export default ChatMessagesListItemAvatar;
