import { useState } from 'react';
import Stack from '@mui/material/Stack';
import { ChatChannel } from 'src/entities/chat';
import ChatMessages from './ChatMessages';
import ChatChannels from './ChatChannels';

function Chat() {
  const [selectedChannel, setSelectedChannel] = useState<ChatChannel>();

  return (
    <Stack height="calc(100vh - 10rem)" direction="row">
      {selectedChannel && <ChatMessages channel={selectedChannel} />}
      <ChatChannels selected={selectedChannel} onSelect={setSelectedChannel} />
    </Stack>
  );
}

export default Chat;
