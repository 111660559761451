import Skeleton, { SkeletonProps } from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { elementsArrFromNumber } from 'src/utils';

const style = {
  gap: 2,
  flexDirection: 'row',
  width: '100%',
};

const iconStyle = {
  mt: 0.5,
  ml: 1.5,
  height: '3.2rem',
  width: '3.2rem',
};

function ChatMessagesListSkeletonRow({ animation }: SkeletonProps) {
  const textSkeleton = elementsArrFromNumber(
    <Skeleton
      animation={animation}
      variant="text"
      height="2.5rem"
      width="100%"
    />,
    2,
  );

  return (
    <Stack sx={style}>
      <Skeleton animation={animation} variant="circular" sx={iconStyle} />
      <Stack flex={1}>
        <Stack direction="row" width="50%" gap={2}>
          {textSkeleton}
        </Stack>
        <Skeleton
          animation={animation}
          variant="text"
          width="80%"
          height="1.5rem"
        />
      </Stack>
    </Stack>
  );
}

export default ChatMessagesListSkeletonRow;
