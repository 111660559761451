import { createAction } from '@reduxjs/toolkit';
import { ChatSetChannelPayload } from './types';
import { CHAT_STORE_SET_CHANNEL, CHAT_STORE_SET_USER_COLOR } from './constants';

export const setChannelAction = createAction<ChatSetChannelPayload>(
  CHAT_STORE_SET_CHANNEL,
);

export const setUsersColorsAction = createAction<string[]>(
  CHAT_STORE_SET_USER_COLOR,
);
