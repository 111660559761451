import { createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit';
import { createStateToStorageSaver } from 'src/helpers';
import { AUTH_STORE_KEY } from './constants';
import { authApi } from './api';
import { logoutAction } from './actions';
import { chatApi } from '../chat';
import { customerApi } from '../customer';
import { fundingOppsApi } from '../fundingOpps';
import { loanAppsApi } from '../loanApps';

export const authStateSaver = createStateToStorageSaver(AUTH_STORE_KEY, [
  customerApi.endpoints.userDetails.matchFulfilled,
  authApi.endpoints.login.matchFulfilled,
  authApi.endpoints.logout.matchFulfilled,
  logoutAction,
]);

export const authLogoutCleaner = createListenerMiddleware();

authLogoutCleaner.startListening({
  matcher: isAnyOf(authApi.endpoints.logout.matchFulfilled, logoutAction),
  effect: (action, { dispatch }) => {
    const apiToReset = [chatApi, customerApi, fundingOppsApi, loanAppsApi];
    apiToReset.forEach((api) => dispatch(api.util.resetApiState()));
  },
});
