import React, { ComponentType, ReactNode } from 'react';
import Stack, { StackProps } from '@mui/material/Stack';
import Skeleton, { SkeletonProps } from '@mui/material/Skeleton';
import { arrayFromNumber } from 'src/utils';
import { SxProps } from 'src/theme/types';

type Props<TProps extends SkeletonProps> = StackProps & {
  children?: ReactNode;
  loading?: boolean;
  rows?: number;
  rowProps?: TProps;
  rowComponent?: ComponentType<TProps>;
  overlayProps?: StackProps;
};

const wrapperStyle: StackProps = {
  maxHeight: '100%',
  position: 'relative',
  gap: 1,
};

const overlayStyle: SxProps = {
  background: ({ palette }) => palette.gradient.bgDefault,
  borderRadius: 2,
  color: 'text.secondary',
  gap: 2,
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  height: '100%',
  width: '100%',
  typography: 'caption',
};

const defaultSkeletonProps: SkeletonProps = {
  variant: 'text',
  width: '100%',
};

function ListSkeleton<TProps extends SkeletonProps = SkeletonProps>({
  children,
  loading,
  overlayProps,
  rows = 5,
  rowComponent: Component = Skeleton,
  rowProps = defaultSkeletonProps as TProps,
  ...rest
}: Props<TProps>) {
  const animation = loading ? undefined : false;

  return (
    <Stack {...wrapperStyle} {...rest}>
      {arrayFromNumber(rows).map((row) => (
        <Component key={row} animation={animation} {...rowProps} />
      ))}
      {!loading && children && (
        <Stack sx={overlayStyle} {...overlayProps}>
          {children}
        </Stack>
      )}
    </Stack>
  );
}

export default ListSkeleton;
