import IconButton from '@mui/material/IconButton';
import SvgIcon from '@mui/material/SvgIcon';
import CurrencyExchangeIcon from 'src/assets/icons/currency-exchange-icon.svg?react';
import CheckIcon from 'src/assets/icons/check-icon.svg?react';
import EditIcon from 'src/assets/icons/edit-icon.svg?react';
import {
  getAllowedOfferActions,
  getOfferDisplayName,
  OfferItem,
} from 'src/entities/offers';
import React, { useContext } from 'react';
import Stack from '@mui/material/Stack';
import { DataGroup } from 'src/entities/dicts';
import TermSheetAccStatus from './TermSheetAccStatus';
import DataListRow, { DataListCell } from '../DataList';
import { TermSheetContext } from './TermSheetContext';
import Tooltip from '../Tooltip';
import TermSheetRowAvatar from './TermSheetRowAvatar';

type Props = {
  group: DataGroup;
  item: OfferItem;
  canManage?: boolean;
};

const style = {
  cursor: 'pointer',
  gridTemplateColumns: '1fr 3fr 4fr 2fr',
  '&>:last-child': { gridColumn: 'auto' },
  '> *': { alignSelf: 'center' },
};

function TermSheetRow({ group, item, canManage }: Props) {
  const {
    showOfferDetailsModal,
    showCounterOfferModal,
    showAcceptOfferModal,
    showEditOfferModal,
  } = useContext(TermSheetContext);
  const { id, acceptanceStatusCode, initiatorType } = item;
  const { canEdit, canRespond } = getAllowedOfferActions(
    item,
    group,
    canManage,
  );
  const { label, value } = getOfferDisplayName(item, group);

  return (
    <DataListRow sx={style} onClick={() => showOfferDetailsModal(item)}>
      <DataListCell
        primary={
          <TermSheetRowAvatar group={group} initiatorType={initiatorType} />
        }
      />
      <DataListCell primary={value} secondary={label} />
      <DataListCell
        primary={<TermSheetAccStatus value={acceptanceStatusCode} />}
      />
      <DataListCell
        sx={{ gridColumn: 'auto' }}
        primary={
          <Stack direction="row" onClick={(e) => e.stopPropagation()}>
            {canEdit && (
              <Tooltip title="Edit term sheet">
                <IconButton
                  size="small"
                  onClick={() => showEditOfferModal(item)}
                >
                  <SvgIcon component={EditIcon} />
                </IconButton>
              </Tooltip>
            )}
            {canRespond && (
              <Tooltip title="Make counter offer">
                <IconButton
                  size="small"
                  onClick={() => showCounterOfferModal(item)}
                >
                  <SvgIcon component={CurrencyExchangeIcon} />
                </IconButton>
              </Tooltip>
            )}
            {canRespond && (
              <Tooltip title="Accept offer">
                <IconButton
                  size="small"
                  onClick={() => showAcceptOfferModal(id)}
                >
                  <SvgIcon component={CheckIcon} />
                </IconButton>
              </Tooltip>
            )}
          </Stack>
        }
      />
    </DataListRow>
  );
}

export default TermSheetRow;
