import { useCallback } from 'react';
import Card from '@mui/material/Card';
import {
  ChannelMessagePersistenceType,
  ChannelMessageType,
  SendChannelMessageCommand,
} from '@aws-sdk/client-chime-sdk-messaging';
import {
  ChatChannel,
  useGetChatSessionQuery,
  useMessagesList,
  useMessagingSession,
  useSetupUsersColors,
} from 'src/entities/chat';
import { useIntersectionObserver } from 'src/hooks';
import ChatMessagesInput from './ChatMessagesInput';
import ChatMessagesList from './ChatMessagesList';
import ChatMessagesHeader from './ChatMessagesHeader';

type Props = {
  channel?: ChatChannel;
};

const style = {
  p: 2,
  mb: 2,
  gap: 2,
  flex: 1,
  height: '100%',
};

function Chat({ channel }: Props) {
  const { data, isLoading: isSessionLoading } = useGetChatSessionQuery();
  const { client, session } = useMessagingSession(data);
  const { messages, loadMessages, isLoading } = useMessagesList({
    channelArn: channel?.channelSummary.channelArn,
    userArn: data?.userArn,
    client,
    session,
  });
  const lastElementRef = useIntersectionObserver<HTMLLIElement>(loadMessages);
  useSetupUsersColors(messages, data?.userArn);

  const handleSendMessage = useCallback(
    (value: string) => {
      const command = new SendChannelMessageCommand({
        Type: ChannelMessageType.STANDARD,
        Persistence: ChannelMessagePersistenceType.PERSISTENT,
        ChannelArn: channel?.channelSummary.channelArn,
        ChimeBearer: data?.userArn,
        Content: value,
      });
      client?.send(command);
    },
    [channel, data, client],
  );

  return (
    <Card sx={style}>
      <ChatMessagesHeader channel={channel} />
      <ChatMessagesList
        isLoading={isSessionLoading || isLoading}
        messages={messages}
        userArn={data?.userArn}
        lastElementRef={lastElementRef}
      />
      <ChatMessagesInput
        disabled={channel?.channelSummary.readonly}
        onSend={handleSendMessage}
      />
    </Card>
  );
}

export default Chat;
