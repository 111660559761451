import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { loadDataFromStorage } from 'src/services';
import { useAppSelector } from 'src/store/hooks';
import { setChannelAction, setUsersColorsAction } from './actions';
import { chatApi } from './api';
import { CHAT_STORE_KEY } from './constants';
import { ChatSetChannelPayload, ChatState } from './types';
import { getUpdatedUsersColors } from './utils';

const initialState: ChatState = loadDataFromStorage(CHAT_STORE_KEY) || {
  channels: {},
  users: {},
};

const reducers = {
  resetChannels: (state: ChatState) => {
    const channels = {};
    return { ...state, channels };
  },
  setChannel: (
    state: ChatState,
    { payload }: PayloadAction<ChatSetChannelPayload>,
  ) => {
    const { channelArn, ...data } = payload;
    return { ...state, channels: { ...state.channels, [channelArn]: data } };
  },
  setUsersColors: (state: ChatState, { payload }: PayloadAction<string[]>) => {
    const users = getUpdatedUsersColors(state.users, payload);
    return { ...state, users };
  },
};

export const chatSlice = createSlice({
  name: CHAT_STORE_KEY,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setUsersColorsAction, reducers.setUsersColors);
    builder.addCase(setChannelAction, reducers.setChannel);
    builder.addMatcher(
      chatApi.endpoints.getChatChannels.matchFulfilled,
      reducers.resetChannels,
    );
  },
});

export const useChatChannelsState = () =>
  useAppSelector(({ chat }) => chat.channels);

export const useChatUserState = (userArn = '') =>
  useAppSelector(({ chat }) => chat.users[userArn]);
