import Box from '@mui/material/Box';
import { TooltipProps } from '@mui/material/Tooltip';
import Tooltip from './Tooltip';

const DEFAULT_LIMIT = 25;

type Props = Omit<TooltipProps, 'children' | 'title'> & {
  limit?: number;
  value: string;
};

const getStyle = (limit: number) => ({
  display: 'inline-block',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  maxWidth: `${limit}rem`,
});

function TruncatedText({ limit = DEFAULT_LIMIT, value, ...rest }: Props) {
  return (
    <Tooltip disabled={Number(value.length) <= limit} title={value} {...rest}>
      <Box component="span" sx={getStyle(limit)}>
        {value}
      </Box>
    </Tooltip>
  );
}

export default TruncatedText;
