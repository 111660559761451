import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import Typography from '@mui/material/Typography';
import Stack, { StackProps } from '@mui/material/Stack';
import { ROOT as ROUTE_ROOT } from 'src/router/constants';
import {
  getChannelDisplayName,
  useGetChatChannelsQuery,
  ChatChannel,
} from 'src/entities/chat';
import ListSkeleton from 'src/components/ListSkeleton';
import NoContent from 'src/components/NoContent';
import ChatChannelsSkeletonRow from './ChatChannelsSkeletonRow';

type Props = {
  selected?: ChatChannel;
  onSelect: (value: ChatChannel) => void;
};

const style: StackProps = {
  direction: 'column',
  gap: 2,
  maxHeight: '100%',
  overflow: 'auto',
};

function ChatChannels({ onSelect, selected }: Props) {
  const navigate = useNavigate();
  const { data, isLoading } = useGetChatChannelsQuery();

  useEffect(() => {
    if (data && !selected) {
      const [item] = data;
      onSelect(item);
    }
  }, [data, selected, onSelect]);

  if (isLoading || !data?.length) {
    return (
      <ListSkeleton
        flex={1}
        rows={8}
        loading={isLoading}
        rowComponent={ChatChannelsSkeletonRow}
      >
        <NoContent
          title="No chat channels"
          description="Please try to check it later"
          actionText="Go home"
          onClick={() => navigate(ROUTE_ROOT)}
        />
      </ListSkeleton>
    );
  }
  return (
    <Stack {...style}>
      <List>
        {data?.map((item) => {
          const { channelSummary } = item;
          const { name, description, helperText, updatedAt } =
            getChannelDisplayName(item);
          return (
            <ListItemButton
              sx={{ borderRadius: 0 }}
              key={channelSummary.channelArn}
              selected={
                selected?.channelSummary.channelArn ===
                channelSummary.channelArn
              }
              onClick={() => onSelect(item)}
            >
              <ListItemText
                primaryTypographyProps={{
                  variant: 'subtitle1',
                  color: 'text.primary',
                  mb: 0.3,
                }}
                primary={name}
                secondary={
                  <>
                    <Typography mb={0.3}>
                      <b>{description}</b> ({helperText})
                    </Typography>
                    {updatedAt}
                    <Typography />
                  </>
                }
              />
            </ListItemButton>
          );
        })}
      </List>
    </Stack>
  );
}

export default ChatChannels;
