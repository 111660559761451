import Stack from '@mui/material/Stack';
import Skeleton, { SkeletonProps } from '@mui/material/Skeleton';
import { elementsArrFromNumber } from 'src/utils';

const style = {
  width: '100%',
};

function ChatChannelsSkeletonRow({ animation }: SkeletonProps) {
  const textSkeleton = elementsArrFromNumber(
    <Skeleton animation={animation} height="1.5rem" variant="text" />,
    2,
  );

  return (
    <Stack sx={style}>
      <Skeleton
        animation={animation}
        variant="text"
        height="3rem"
        width="35%"
      />
      {textSkeleton}
    </Stack>
  );
}

export default ChatChannelsSkeletonRow;
