import { useCallback, useState } from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SvgIcon from '@mui/material/SvgIcon';
import IconButton from '@mui/material/IconButton';
import SendIcon from 'src/assets/icons/send-icon.svg?react';

type Props = {
  onSend?: (message: string) => void;
};

function ChatMessagesInput({ onSend }: Props) {
  const [value, setValue] = useState('');

  const handleSendMessage = useCallback(() => {
    if (value) {
      onSend?.(value);
      setValue('');
    }
  }, [onSend, setValue, value]);

  return (
    <TextField
      value={value}
      onKeyDown={({ key }) => key === 'Enter' && handleSendMessage()}
      onChange={({ target }) => setValue(target.value)}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <InputAdornment position="end">
              <IconButton
                data-testid="masked-input-btn"
                disabled={!value}
                onClick={handleSendMessage}
                size="small"
              >
                <SvgIcon component={SendIcon} />
              </IconButton>
            </InputAdornment>
          </InputAdornment>
        ),
      }}
    />
  );
}

export default ChatMessagesInput;
